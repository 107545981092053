import { talentViewApi } from './ApiService';
import { ApiResponse } from '../interfaces/Api';
import { ICompanyResearchRequestDTO } from '../interfaces/Support';

const BASE_API = '/support';

export class SupportService {
  public static async postRequestCompanyResearch (companyResearchRequest: ICompanyResearchRequestDTO): Promise<ApiResponse<null>> {
    const response = await talentViewApi.getAxios().post(`${BASE_API}/request-company-research`, companyResearchRequest);
    return response?.data as ApiResponse<null>;
  }

  public static async postRequestUpgrade (): Promise<ApiResponse<null>> {
    const response = await talentViewApi.getAxios().post(`${BASE_API}/request-upgrade`);
    return response?.data as ApiResponse<null>;
  }
}
