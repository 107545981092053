import { Button } from '@material-ui/core';
// eslint-disable-next-line no-use-before-define
import * as React from 'react';
import { useState } from 'react';
import { UpgradeDialog } from '../UpgradeDialog/UpgradeDialog';
import StarIcon from '@material-ui/icons/Star';
import { useSnackbar } from 'notistack';

export const FreeTrialUpgradeButton = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [showUpgradeDialog, setShowUpgradeDialog] = useState(false);

  const handleUpgradeClick = () => {
    // Sets the showUpgradeDialog to true
    setShowUpgradeDialog(true);
  };

  // Event handler to set the showUpgradeDialog to false
  const handleUpgradeDialogClose = () => {
    setShowUpgradeDialog(false);
  };

  // Event handler for upgrade dialog submit
  const handleUpgradeDialogSubmit = async () => {
    enqueueSnackbar('Thank you for your interest in upgrading, we\'ll be in touch shortly!', { variant: 'success' });
    handleUpgradeDialogClose();
  };

  return (<>
    <Button
      variant="contained"
      color="primary"
      size="small"
      startIcon={<StarIcon />}
      onClick={handleUpgradeClick}
    >
        Upgrade
    </Button>
    <UpgradeDialog
      open={showUpgradeDialog}
      onClose={handleUpgradeDialogClose}
      onSubmit={handleUpgradeDialogSubmit}
    />
  </>);
};
