const ERROR_MESSAGES = {
  GENERIC: 'Action failed, please try again later.',
  ADD_LIST: 'Failed to add list, please try again later.',
  RENAME_LIST: 'Failed to rename list, please try again later.',
  DELETE_LIST: 'Failed to delete list, please try again later.',
  RENAME_SAVED_SEARCH: 'Failed to rename saved search, please try again later.',
  DELETE_SAVED_SEARCH: 'Failed to delete saved search, please try again later.',
  EXPORT: 'Export failed, please try again later.',
  RENAME_EXPORT: 'Failed to rename export, please try again later.',
  DELETE_EXPORT: 'Failed to delete export, please try again later.',
  CONTACTS_LOAD: 'Unable to load contacts.',
  DELETE_USER: 'Failed to delete user, please try again later.',
  USER_DEACTIVATED: 'Failed to deactivate user, please try again later.',
  USER_REACTIVATED: 'Failed to reactivate user, please try again later.',
  ACCOUNT_SETUP_ERROR: 'Failed to finish setting up account. Please try again or contact support.',
  FILE_SIZE_TOO_LARGE: 'File size too large, please keep file size under 20MB.'
};

const SUCCESS_MESSAGES = {
  EXPORT: 'Export complete!',
  DELETE_USER: 'User deleted!',
  USER_DEACTIVATED: 'User deactivated!',
  USER_REACTIVATED: 'User reactivated!',
  ACCOUNT_SETUP_SUCCESS: 'Account setup complete!'
};

const INFO_MESSAGES = {
  NO_CREDITS_AVAILABLE: 'No credits available.'
};

const HINT_MESSAGES = {
  CREDIT_USAGE: 'Contact info that has been unlocked will be available for a minimum of 30 days. If the contact info has changed after the 30 days, a credit will need to be used to access the new information.'
};

export const MESSAGES = {
  SUCCESS: {
    ...SUCCESS_MESSAGES
  },
  ERROR: {
    ...ERROR_MESSAGES
  },
  INFO: {
    ...INFO_MESSAGES
  },
  HINT: {
    ...HINT_MESSAGES
  }
};
