import { ContentConnectCampaignStatus } from '../../enums/ContentConnectCampaignStatus';
import { EmailCampaignAnalytics } from '../../interfaces/EmailCampaign/EmailCampaignAnalytics';
import { User } from '../../interfaces/User/User';

export const getCampaignStatusDisplayValue = (status: ContentConnectCampaignStatus) => {
  switch (status) {
    case ContentConnectCampaignStatus.DRAFTED:
      return 'Drafted';
    case ContentConnectCampaignStatus.ACTIVE:
      return 'Active';
    case ContentConnectCampaignStatus.STOPPED:
      return 'Stopped';
    case ContentConnectCampaignStatus.PAUSED:
      return 'Paused';
    case ContentConnectCampaignStatus.COMPLETED:
      return 'Completed';
    case ContentConnectCampaignStatus.ARCHIVED:
      return 'Archived';
    default:
      return 'Unknown';
  }
};

export const doesUserHaveEmailCampaignsAccess = (isInternal: boolean, user?: User|null) => {
  return isInternal || (user?.isContentConnect && !user?.isDemoUser);
};

// In progress leads should be weighted as having sent 1.75 emails since they've probably all had at least one email sent.
const IN_PROGRESS_LEADS_WEIGHT = 1;

// Provides the completion percentage for the campaign and closely matches the estimated progress provided by Smartlead (usually).
export const getCompletionPercentage = (analytics: EmailCampaignAnalytics) => {
  // Formula (completed + (in progress * 1.75)) / (total - blocked - stopped - paused)
  return Math.round(((analytics.campaignLeadsCompleted + (analytics.campaignLeadsInProgress * IN_PROGRESS_LEADS_WEIGHT)) / (analytics.campaignLeadsTotal - analytics.campaignLeadsBlocked - analytics.campaignLeadsPaused - analytics.campaignLeadsStopped)) * 100);
};

export const getOpenRate = (totalOpened, numberSent) => {
  if (!totalOpened || !numberSent) {
    return '-';
  }

  // Formula (opened / sent)
  return Math.round((totalOpened / numberSent) * 100) || 0;
};

export const getClickRate = (totalClicked, totalOpened) => {
  if (!totalClicked || !totalOpened) {
    return '-';
  }

  // Formula (clicked / sent)
  return Math.round((totalClicked / totalOpened) * 100) || 0;
};
