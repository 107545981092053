import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError } from 'axios';
import { ICompanyResearchRequestDTO } from '../interfaces/Support';
import { SupportService } from '../services/SupportService';
import { ApiResponse } from '../interfaces/Api';

export const useRequestCompanyResearch = (): UseMutationResult<ApiResponse<null>, AxiosError, ICompanyResearchRequestDTO> => {
  return useMutation({
    mutationFn: SupportService.postRequestCompanyResearch
  });
};

export const useRequestUpgrade = (): UseMutationResult<ApiResponse<null>, AxiosError, void> => {
  return useMutation({
    mutationFn: SupportService.postRequestUpgrade
  });
};
