import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField
} from '@material-ui/core';
// eslint-disable-next-line no-use-before-define
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { DialogForm, DialogFormActions } from '../../elements/Dialog/Dialog';
import { Button } from '../../elements/Button/Button';
import styled from 'styled-components';
import { useFinishAccount } from '../../hooks/useUser';
import { useSnackbar } from 'notistack';
import { MESSAGES } from '../../constants/Messages';
import { TERMS_OF_SERVICE_URL } from '../../constants/App';

const WelcomeMessage = styled(DialogTitle)`
  position: relative;
  text-align: center;
  
  &:after {
    content: "";
    display: block;
    margin: .2rem auto 0;
    min-height: 4px;
    width: 14rem;
    background-color: ${props => props.theme.palette.secondary.main};
    border-radius: 1rem;
  }
`;

const Form = styled(DialogForm)`
  margin-top: .75rem;
  grid-template-columns: 1fr 1fr;
  min-width: 30rem;
`;

const TermsAgreement = styled(FormControlLabel)`
  grid-column: 1 / -1;
`;

interface IFinishAccountDialogProps {
    open: boolean;
    onClose: () => void;
}

export const FinishAccountDialog = ({ open, onClose } : IFinishAccountDialogProps) => {
  const { mutateAsync: sendFinishAccountData, isLoading } = useFinishAccount();
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const data = new FormData(evt.target);
    const dto = {
      firstName: data.get('firstName') as string,
      lastName: data.get('lastName') as string,
      organization: data.get('organization') as string,
      website: data.get('website') as string
    };

    try {
      await sendFinishAccountData(dto);
      enqueueSnackbar(MESSAGES.SUCCESS.ACCOUNT_SETUP_SUCCESS, { variant: 'success' });
      handleClose();
    } catch (e) {
      enqueueSnackbar(MESSAGES.ERROR.ACCOUNT_SETUP_ERROR, { variant: 'error' });
    }
  };

  return <Dialog
    open={open}
    onClose={handleClose}
    disableBackdropClick
    disableEscapeKeyDown
  >
    <WelcomeMessage>Welcome to TalentView!</WelcomeMessage>
    <DialogContent>
      <Typography variant={'body2'}>We need a little more information to finish setting up your account.</Typography>
      <Form onSubmit={handleSubmit}>
        <TextField
          required
          id='first-name'
          name='firstName'
          label='First Name'
          disabled={isLoading}
        />
        <TextField
          required
          id='last-name'
          name='lastName'
          label='Last Name'
          disabled={isLoading}
        />
        <TextField
          required
          id='organization'
          name='organization'
          label='Company'
          disabled={isLoading}
        />
        <TextField
          required
          id='website'
          name='website'
          label='Website'
          disabled={isLoading}
        />
        <TermsAgreement
          control={<Checkbox required />}
          label={<Typography>I have read and agree to the <a target="_blank" href={TERMS_OF_SERVICE_URL}>terms of service</a>.</Typography>}
        />
        <DialogFormActions>
          <Button
            type='submit'
            disabled={isLoading}
            endIcon={isLoading ? <CircularProgress size={15} /> : null}
          >
          Finish
          </Button>
        </DialogFormActions>
      </Form>
    </DialogContent>
  </Dialog>;
};
