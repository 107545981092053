// eslint-disable-next-line no-use-before-define
import * as React from 'react';
import { useContext, useState } from 'react';
import styled from 'styled-components';
import { LinearProgress, Menu, MenuItem } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { UserContext } from '../../contexts/User/User';
import { NavLink } from 'react-router-dom';
import { APP_ROUTES, PUBLIC_ROUTES } from '../../constants/Routes';
import { FLAGS } from '../../constants/Flags';
import { useCreditsUsage } from '../../hooks/useCredits';
import Typography from '@material-ui/core/Typography';
import parse from 'date-fns/parse';
import { addDays } from 'date-fns';
import { Tooltip } from '../../elements/Tooltip/Tooltip';
import format from 'date-fns/format';
import { useAuth0 } from '@auth0/auth0-react';

const UsageInfo = styled.div`
  display: grid;
  padding: 0.5rem 0.5rem 0;
  min-width: 8rem;
`;

const UsageDetails = styled(Typography)`
  margin-left: auto;
`;

// TODO variablize the profile circle
const ProfileCircle = styled.div`
  height: 2rem;
  width: 2rem;
  background-color: ${props => props.theme.palette.secondary.main};
  color: #fff;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  
  &:hover {
    background-color: ${props => props.theme.palette.secondary.light};
  }
`;

const ProfileContainer = styled.div`
    
`;

const ProfileInfo = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

interface AppProfileMenuProps {
  className?: string,
}

export const AppProfileMenu = ({ className }: AppProfileMenuProps) => {
  const user = useContext(UserContext);
  const [menuRef, setMenuRef] = useState<null|HTMLElement>(null);
  const { logout } = useAuth0();

  const openProfile = (evt) => {
    setMenuRef(evt.currentTarget);
  };

  const closeProfile = () => {
    setMenuRef(null);
  };

  const handleLogout = async () => {
    await logout({
      logoutParams: {
        returnTo: window.location.origin + PUBLIC_ROUTES.LOGIN()
      }
    });
    closeProfile();
  };

  const { userCredits } = useCreditsUsage();
  let usageResetDate: Date|null = null;

  if (userCredits?.creditUsage) {
    const periodEndDate = parse(userCredits?.creditUsage.currentPeriodEnd, 'yyyy-MM-dd', new Date());
    usageResetDate = addDays(periodEndDate, 1);
  }

  return <ProfileContainer className={className}>
    <ProfileInfo onClick={openProfile}>
      <ProfileCircle>
        {!user && <Skeleton variant="text" />}
        {user && !!user.firstName && !!user.lastName && `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`}
      </ProfileCircle>
    </ProfileInfo>
    <Menu
      id='profile-menu'
      anchorEl={menuRef}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'top'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      keepMounted
      open={Boolean(menuRef)}
      onClose={closeProfile}
    >
      {
        !!user && FLAGS.INTEGRATIONS && !user.isDemoUser && user.roleLevel <= 3 &&
          <MenuItem onClick={closeProfile} component={NavLink} to={APP_ROUTES.INTEGRATION_SETTINGS()}>Integrations (Beta)</MenuItem>
      }
      <MenuItem onClick={closeProfile} component={NavLink} to={APP_ROUTES.USER_SETTINGS()}>Settings</MenuItem>
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
      {!!user?.isDemoUser && <Tooltip title={`Resets on ${usageResetDate ? format(usageResetDate, 'MMM d, yyyy') : '-'}`}>
        <UsageInfo>
          <LinearProgress
            variant="determinate"
            color="secondary"
            value={(userCredits?.isLoading || !userCredits?.creditUsage) ? 0 : (userCredits?.creditUsage.creditsUsed / userCredits?.creditUsage.creditsAllowed) * 100}
          />
          <UsageDetails variant="caption">{(userCredits?.isLoading || !userCredits?.creditUsage) ? 'Unable to load credit usage' : `${userCredits?.creditUsage.creditsUsed} / ${userCredits?.creditUsage.creditsAllowed} credits`}</UsageDetails>
        </UsageInfo>
      </Tooltip>}
    </Menu>
  </ProfileContainer>;
};
