import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
// eslint-disable-next-line no-use-before-define
import * as React from 'react';
import { useRequestUpgrade } from '../../hooks/useSupport';

interface UpgradeDialogProps {
    open: boolean;
    onClose: () => void;
    onSubmit: () => void;
}

export const UpgradeDialog = ({ open, onClose, onSubmit }: UpgradeDialogProps) => {
  const { mutateAsync: sendUpgradeRequest, isLoading } = useRequestUpgrade();
  const handleSubmit = async () => {
    await sendUpgradeRequest();
    onSubmit();
  };

  return <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="upgrade-dialog-title"
  >
    <DialogTitle id="upgrade-dialog-title">Request Upgrade</DialogTitle>
    <DialogContent>
      <DialogContentText>
          Interested in downloading unlimited data out of TalentView? Request info below and we’ll send pricing details.
      </DialogContentText>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="primary" disabled={isLoading}>Never mind</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary" disabled={isLoading} endIcon={isLoading ? <CircularProgress size={15} /> : null}>Request Info</Button>
      </DialogActions>
    </DialogContent>
  </Dialog>;
};
